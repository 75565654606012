<template>
  <ModalWrapper @close="closeAction" :isFoot="isFoot">
    <!-- Head -->
    <template #head>
      <div class="flex ai-c ggap-20">
        <h1 class="title">Создание статуса</h1>
        <div class="flex ai-c ggap-5">
          <BaseIcon
            class="ic-16"
            :class="formData.is_static ? 'grey' : 'primary'"
            :icon="formData.is_static ? 'lock' : 'unlock'"
          />
          {{ statusType }}
        </div>
      </div>
    </template>

    <!-- Body -->
    <template #default>
      <div class="status-add pos-r grid ai-fs">
        <BaseLoad v-if="isLoad" class="pos-a grid jc-c z99999 white-transparent" />

        <form class="p-20 grid ggap-20" id="form-add">
          <section class="flex ai-c ggap-10">
            <div class="flex ai-c ggap-10">
              <h4 class="title">Превью статуса:</h4>
              <small class="marker" :style="{ color: formData.text_color, backgroundColor: formData.background_color }">
                {{ formData.title || '-' }}
              </small>
            </div>
          </section>
          <section class="grid gtc-2 ggap-30">
            <FormInput
              class="w-100"
              label="Название"
              placeholder="Название"
              v-model="formData.title"
              @keydown.enter.prevent
              required
            />
            <div class="grid gtc-2">
              <FormColorPicker label="Цвет текста" v-model="formData.text_color" required />
              <FormColorPicker label="Цвет фона" v-model="formData.background_color" required />
            </div>
          </section>
        </form>
      </div>
    </template>

    <!-- Foot -->
    <template #foot>
      <div class="flex ai-c jc-sb ggap-20 h-100">
        <button class="btn grey" @click="closeAction">Отмена</button>
        <button class="btn primary" @click="submitAction">
          <BaseIcon class="ic-16 white" icon="check-circle" />
          Отправить
        </button>
      </div>
    </template>
  </ModalWrapper>
</template>

<script setup>
import statuses from '@/api/modules/statuses'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { computed, ref } from 'vue'
import { ModalWrapper, BaseLoad, BaseIcon, FormInput, FormColorPicker } from '@/components'
import { isInvalidForm, $busEmit } from '@/plugins'

// Data
const store = useStore()
const router = useRouter()
const isLoad = ref(false)
const isFoot = ref(true)
const formData = ref({
  text_color: '#000',
  background_color: '#EEE'
})

// Computed
const statusType = computed(() => (formData.value.is_static ? 'Статичный' : 'Кастомный'))

// Methods
function closeAction() {
  router.replace('/settings/statuses')
}
function submitAction() {
  if (isInvalidForm('form-add')) return
  isLoad.value = true
  isFoot.value = false
  const payload = {
    ...formData.value,
    is_static: false
  }
  statuses
    .create(payload)
    .then(async () => {
      await store.dispatch('helpers/GET_STATUSES')
      isLoad.value = false
      closeAction()
      $busEmit('setToast', {
        type: 'green',
        icon: 'check-circle',
        message: 'Статус успешно создан, можете в любое время отредактировать его или удалить.'
      })
    })
    .finally(() => {
      isLoad.value = false
      isFoot.value = true
      // $busEmit('setToast', {
      //   type: 'red',
      //   icon: 'alert-triangle',
      //   title: e.response.status + ' ' + e.response.statusText,
      //   message: 'Что-то пошло не так, обратитесь к администратору, или попробуйте еще раз'
      // })
    })
}
</script>

<style lang="scss" scoped>
.status-add {
  width: 600px;
  min-height: 160px;
}
</style>
